import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
// import { Headers, Http, RequestOptions, RequestMethod } from '@angular/http';
//
import { environment } from '../../../../environments/environment';
import { tap, catchError, switchMap, concatMap, map, withLatestFrom, mergeMap, flatMap, merge } from 'rxjs/operators';
// import { of } from 'rxjs/observable/of';
// import { from } from 'rxjs/observable/from';

import * as UserActions from './user.actions';
// import * as FavActions from '../../../providers/listing/favStore/fav.actions';
// import * as SaleActions from '../../../providers/listing/saleStore/sale.actions';
// import * as SoldActions from '../../../providers/listing/soldStore/sold.actions';
// import * as InboxActions from '../../../providers/inbox/store/inbox.actions';
// import * as IapActions from '../../../providers/iap/store/iap.actions';
// import { RestProvider, Token } from '../../../providers/rest/rest.provider';
// import { RestError} from '../../../providers/rest/rest.types';
// import { Storage } from '@ionic/storage';
// import { Platform, App, Events } from 'ionic-angular';
// import { NativeStorage } from '@ionic-native/native-storage';
import { Store } from '@ngrx/store';
import * as fromApp from '../../../app.reducer';
// // import { ListingProvider } from '../../../providers/listing/listing.provider';
// import { ServiceProvider } from '../../service/service';
// import { Observable } from 'rxjs/Observable';
// import { User, UserRegistrationViewModel, UserSettings, UserMaker, UserProvider } from '../../../providers/user/user.provider';
// import * as AuthActions from '../../../pages/login/store/login.actions';
// import { PushProvider } from '../../../providers/push/push';
// import { DataProvider } from '../../../providers/data/data';
import { UserService, User, UserMaker } from '../user.service';
import { EMPTY, of } from 'rxjs';
import { ChatService } from '../../chat/chat.service';
import { ModalController } from '@ionic/angular';
import { TutorialPage } from 'src/app/pages/tutorial/tutorial.page';

@Injectable()
export class UserEffects {

  constructor(
    private actions$: Actions,
    // private httpClient :HttpClient,
    // private http: Http,
    // private storage: Storage,
    // private platform: Platform,
    // private nativeStorage: NativeStorage,
    // private app: App,
    // private events: Events,
    // private service: ServiceProvider,
    // private rest: RestProvider,
    private store: Store<fromApp.AppState>,
    // private pushProvider: PushProvider,
    // private dataProvider: DataProvider,
    // private userProvider: UserProvider
    private userService: UserService,
	private chatService: ChatService,
	private modalController: ModalController
  ){}

  getUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.GET_USER),
      switchMap(() =>
        this.userService.getUser().pipe(
          switchMap((data: User | any) => {

            console.log('user returned:');
            console.log(data);

            if(data && data['pk']) {
              console.log('return set user action');
              return of(new UserActions.SetUser(UserMaker.create(data)));
            } else {
              console.log('return set user failed');
              return of(new UserActions.SetUserFailed());
            }
            
          })
        )
      )
    )
  );

  // getStorage(key:string):Promise<any> {
  //   if(this.platform.is('cordova') && (this.platform.is('ios') || this.platform.is('android'))){
  //     // native storage
  //     return this.nativeStorage.getItem(key)
  //   }else{
  //     // ionic storage
  //     return this.storage.get(key)
  //   }
  // }
  //
  // lookupUserSearches(){
  //   this.userProvider.getUserSearches()
  //   .subscribe((data)=> {
  //     console.log('did lookup user searches');
  //     console.log(data);
  //
  //
  //   })
  // }
  //
  // @Effect()
  // getUser = this.actions$.pipe(
  //   ofType(UserActions.GET_USER),
  //   switchMap( ()=> {
  //     console.log('get user effects !!!!');
  //     return this.httpClient.get<User>( environment.base_api_url+'/auth/users/me/')
  //     .pipe(catchError( (data):any => {
  //             console.log('there was a problem fetching the user!');
  //             console.log(data);
  //           })
  //       )
  //   }),
  //   mergeMap( (user:User) => {
  //     let defaultSettings:UserSettings = {
  //       push: null,
  //       tutorial: null,
  //       location: null
  //     };
  //     console.log('checking storage for settings..');
  //     return Observable.fromPromise(
  //       this.getStorage('settings')
  //       .then(
  //         (data:any):UserSettings => {
  //           console.log('returned data from storage:');
  //           console.log(data);
  //           if(data){
  //             return JSON.parse(data)
  //           }
  //           // console.log(JSON.parse(data));
  //           // return JSON.parse(data);
  //         },
  //         error => console.error(error)
  //       )
  //     )
  //     .pipe(
  //       tap((data)=> {
  //         console.log('tried storage:');
  //         console.log(data);
  //       }),
  //       catchError( (err) => {
  //         console.log('could not load local storage!');
  //         if(err){
  //           console.log(err);
  //         }
  //         return Observable.of({ ...user, settings: defaultSettings })
  //       })
  //     )
  //     .map((response:UserSettings):User => {
  //       console.log('map pipe storage "settings" returned ');
  //       console.log(response);
  //       if(!response){
  //         return UserMaker.create({ ...user, settings: defaultSettings })
  //       }
  //       return UserMaker.create({ ...user, settings: response })
  //     })
  //   }),
  //   concatMap( (userWithSettings:User) => of(new UserActions.SetUser(userWithSettings)))
  // );
/* getThreads... */

	checkSeenTutorialOnLogin$ = createEffect(() =>
		this.actions$.pipe(
			ofType(UserActions.SET_USER),
			tap(async () => {
				const seenTutorial = localStorage.getItem('seenTutorial');
				if (!seenTutorial) {
					console.log('show tutorial modal');
					const modal = await this.modalController.create({
						component: TutorialPage
					});
					await modal.present();
					await modal.onDidDismiss().then(() => {
						const currentTime = new Date().toISOString();
                    	localStorage.setItem('seenTutorial', currentTime);
					});
				}
			})
		),
		{ dispatch: false }
	);

	loadUserThreadsOnLogin$ = createEffect(() =>
		this.actions$.pipe(
			ofType(UserActions.SET_USER),
			switchMap(() => 
				this.chatService.getAllThreads().pipe(
					tap(threads => {
						console.log('Loaded threads:', threads);
					}),
					catchError(error => {
						console.error('Error loading threads:', error);
						return EMPTY;
					})
				)
			)
		),
		{ dispatch: false }
	);

	clearChatThreadsOnLogout$ = createEffect(() =>
		this.actions$.pipe(
			ofType(UserActions.LOGOUT),
			tap(() => {
				this.chatService.clearThreads();
				console.log('Chat threads cleared on logout.');
			})
		),
		{ dispatch: false }
	);
  //
  // @Effect()
  // createUser = this.actions$.pipe(
  //   ofType(UserActions.CREATE_USER),
  //   tap( (data)=> {
  //     console.log('CREATE_USER effects !!!!');
  //     console.log(data);
  //   }),
  //   switchMap( (data:UserActions.CreateUser)=> {
  //     console.log('CREATE_USER effects !!!!');
  //     console.log(data.payload);
  //
  //     return this.http.post(`${environment.base_api_url}/auth/users/`, data.payload,
  //     new RequestOptions({
  //         headers: new Headers({
  //             'Content-Type': 'application/json',
  //             'Accept': 'application/json; version='+environment.api_version
  //         }),
  //         method: RequestMethod.Post,
  //         body: data.payload,
  //         url: `${environment.base_api_url}/auth/users/`
  //     }))
  //     .map((res) => res.json())
  //     .catch((error) => of(new RestError(error, 'Problem making new user POST.')))
  //     .pipe(
  //       tap((data)=> {
  //         console.log('checking user registration progress:');
  //         console.log(data);
  //       }),
  //       catchError( (data):any => {
  //         console.log('there was a problem creating the user!');
  //         console.log(data);
  //       })
  //     )
  //     .concatMap((result:any | RestError | User ):Observable<any> => {
  //       if(!result || result['error'] || result instanceof RestError){
  //         return of(new UserActions.CreateFailed(result));
  //       }
  //       console.log('created user result:');
  //       console.log(result);
  //
  //       return this.http.post(`${environment.base_api_url}/auth/jwt/create/`, {
  //         email: data.payload.email,
  //         password: data.payload.password
  //       })
  //       .map((res) => res.json())
  //       .concatMap( (response:Token|any) => {
  //         console.log('after jwt/create');
  //         console.log(response);
  //         if(response && response['access'] && result['id']){
  //           delete data.payload['password'];
  //           delete data.payload['username'];
  //           delete data.payload['email'];
  //           let patchedUser = { ...data.payload };
  //           console.log('patching user with:');
  //           console.log(patchedUser);
  //
  //           this.store.dispatch(new AuthActions.ValidToken(response));
  //
  //           return this.http.patch(`${ environment.base_api_url }/auth/users/me/`,
  //           patchedUser,
  //           new RequestOptions({
  //               headers: new Headers({
  //                   'Content-Type': 'application/json',
  //                   'Accept': 'application/json; version='+environment.api_version,
  //                   'Authorization': 'JWT ' + response['access']
  //               }),
  //               method: RequestMethod.Patch,
  //               body: patchedUser,
  //               url: `${ environment.base_api_url }/auth/users/me/`
  //           }))
  //           .map((res) => <User>res.json())
  //           .map((finalData:User|any)=> {
  //             console.log("user create and patch concatMap:");
  //             console.log(finalData);
  //             if(finalData && finalData['first_name']){
  //               console.log("we have created a user!!");
  //               this.dataProvider.newUserWasCreated = finalData;
  //               finalData['settings'] = {
  //                 push: '',
  //                 tutorial: false,
  //                 location: null,
  //                 pk: ''
  //               };
  //               console.log('returning SetUser()');
  //               return new UserActions.SetUser(finalData)
  //             }
  //           })
  //           .catch((error) => of(new RestError(error, 'Problem making new user PATCH.')))
  //         }
  //       })
  //       .catch((error) => of(new RestError(error, 'Problem making create-login POST.')));
  //     })
  //
  //   })
  // )
  //
  // @Effect({ dispatch: false })
  // logoutUser = this.actions$.pipe(
  //   ofType(UserActions.LOGOUT),
  //   tap( (data)=> {
  //     console.log('UserActions.LOGOUT user.effects !!!!');
  //
  //     if(this.pushProvider.pushIsActive){
  //       console.log('this user had push activated.. will shut down.');
  //       this.pushProvider.stopPush();
  //     }
  //     if(this.service.loading){
  //       this.service.loading.dismiss()
  //     }
  //   })
  // )
}
