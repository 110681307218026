/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { BehaviorSubject } from 'rxjs';
import { Token, TokenMaker } from '../auth/auth.types';

@Injectable({
  providedIn: 'root'
})
export class StorageService {


	public storedTokenSubject = new BehaviorSubject<Token>(null);
	private _storage: Storage | null = null;
	private _loadResolve: any;
	private _init = false;
	private tokenKey = 'tokens';

	constructor(
		private storage: Storage
	) {
		this.init();
	}

	async init() {
		// If using, define drivers here: await this.storage.defineDriver(/*...*/);
		const storage = await this.storage.create();
		this._storage = storage;
		console.log('storage has been set.');
		if(!this._init) {
			this._init = true;
			this.checkStoredToken();
		}
	}

	public setToken(key: string, value: any) {
		this._storage?.set(key, value);
	}

	public getToken(key: string) {
		return this._storage?.get(key);
	}

	public clear(key: string): Promise<any> {
		return this._storage?.remove(key);
	}

	public clearAuthStorage(): Promise<any> {
		return this.clear(this.tokenKey);
	}

	private checkStoredToken(): void {
		console.log('checkStoredToken()');
		this.getToken(this.tokenKey)
		.then((data)=> {
			if(data && data.access && data.refresh){
				this.storedTokenSubject.next(TokenMaker.create(data));
			}
		});
	}

}
