/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable id-blacklist */
/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { RestService } from '../rest/rest.service';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import * as UserActions from './store/user.actions';
import { Store, select } from '@ngrx/store';
import { RestError } from '../rest/rest.types';
import * as fromApp from '../../app.reducer';
import { getUser } from './store/user.selectors';
import { AuthService } from '../auth/auth.service';

export class SupportTicketInput{
	constructor(
	  public name: string,
	  public autorespond: boolean,
	  public source: string,
	  public email: string,
	  public subject: string,
	  public message: string,
	  public reason: string,
	  public user_pk: string,
	  public version: string,
	  public platform: string,
	  public id?: any
	) {};
  }
  export class TicketMaker{
	static create(e: SupportTicketInput){
	  return new SupportTicketInput(
		e.name,
		e.autorespond,
		e.source,
		e.email,
		e.subject,
		e.message,
		e.reason,
		e.user_pk,
		e.version,
		e.platform,
		e.id
	  );
	}
}
export class UserRegistration {
  constructor(
    public email: string,
    public password: string,
    public first_name: string,
    public last_name: string,
    public allow_marketing?: boolean
  ){}
}
export class User {
  constructor(
    public pk: string,
    public first_name: string,
    public last_name: string,
    public avatar: string,
    public bio: string,
    public primary_email: string,
    public other_emails: any,
    public email_verified: boolean,
    public phone: Phone,
    public phone_verified: boolean,
    public facebook_user_id: string,
    public facebook_connected: any,
    public google_user_id: any,
    public google_connected: any,
    public allow_marketing: boolean,
    public date_joined: string,
    public modified: string,
    public location: any,
    public blocked_users: BlockedUser[],
    public reported_listings: any,
    public is_staff: boolean,
    public settings?: UserSettings
  ) {};
}
export class UserMaker {
  static create(e: User){
    return new User(
      e.pk,
      e.first_name,
      e.last_name,
      e.avatar,
      e.bio,
      e.primary_email,
      e.other_emails,
      e.email_verified,
      e.phone,
      e.phone_verified,
      e.facebook_user_id,
      e.facebook_connected,
      e.google_user_id,
      e.google_connected,
      e.allow_marketing,
      e.date_joined,
      e.modified,
      e.location,
      e.blocked_users,
      e.reported_listings,
      e.is_staff,
      e.settings
    );
  }
}
export class Phone {
  public number: string;
  public is_verified: boolean;
}
export class BlockedUser {
  constructor(
    public pk: string,
    public first_name: string,
    public last_name: string
  ) {};
}

export class UserSettings {
  public push: string;
  public tutorial: boolean;
  public location: boolean;
  public pk?: string;
}

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private _user: User;

  constructor(
    private restService: RestService,
    private store: Store<fromApp.AppState>,
	private authService: AuthService
  ) {
    this.store.pipe(select(getUser)).subscribe((user: User) => {
      this.user = user;
    });
  }

  public get user(): User {
    return this._user;
  }

  public set user(user: User) {
    console.log('did set user on UserService from store selector');
    console.log(user);
    this._user = user;
  }

  getUser() {
    console.log('user service getUser()');
    return this.restService.get(environment.base_api_url+'/auth/users/me/');
    // .pipe(
    //   map((data:User)=> {
    //     if(data && data.hasOwnProperty('pk')){
    //       this._user = UserMaker.create(data);
    //     }
    //   })
    // )
  }

  createUser(data) {
    console.log('user service createUser()');
    return this.restService.post(environment.base_api_url+'/auth/users/', data);
  }

  sendPasswordReset(email: string) {
    console.log('sendPasswordReset()');
    return this.restService.post(environment.base_api_url+'/auth/users/reset_password/', {
      email
    });
  }

  getUserById(userPk: string): Observable<any> {
    return this.restService.get(environment.base_api_url+'/auth/users/'+userPk);
  }

  public patchUser(userData: Partial<User>): Observable<User | any> {
    return this.restService.patch(
      `${ environment.base_api_url }/auth/users/me/`,
      userData
    )
    .pipe(
      map((user: User) => {
        this.store.dispatch(new UserActions.SetUser(user));
        return user as User;
      }),
      catchError((error: any) => {
        console.error('Error updating user:', error);
        this.store.dispatch(new UserActions.SetUserFailed());
        return of(error);
      })
    );
  }

  public sendCode(): Observable<any | RestError> {
    return this.restService.post(environment.base_api_url+'/auth/phone/resend_code/', null);
  }

  public sendLink(primary_email_pk: string) {
    return this.restService.post(environment.base_api_url + '/auth/emails/'+primary_email_pk+'/send_confirmation/', {
      id: primary_email_pk
    });
  }

  public getUserEmails(): Observable<any> {
	return this.restService.get(environment.base_api_url+'/auth/emails/');
  }

  public addPhone(phone: string): Observable<any | RestError> {
	return this.restService.post(environment.base_api_url+'/auth/phone/', {
		// eslint-disable-next-line id-blacklist
		number: phone
	});
  }

  public deletePhone(): Observable<any | RestError> {
	return this.restService.delete(environment.base_api_url+'/auth/phone/delete_phone/');
  }

  public verifyPhoneCode(code: string): Observable<any | RestError> {
	return this.restService.post(environment.base_api_url + '/auth/phone/verify/', {
		code
	});
  }

  public getPurchaseHistory(next?: string): Observable<any | RestError> {
	return this.restService.get(next ? next : environment.base_api_url+'/purchases/');
  }

  public unblockUser(id: string): Observable<any | RestError> {
	return this.restService.delete(environment.base_api_url+'/auth/blocking/'+id);
  }

  public blockUser(target_user: string): Observable<any | RestError> {
	return this.restService.post(environment.base_api_url+'/auth/blocking/', {
		target_user
	});
  }

  /*
  var obj = {
      'message': this.message,
      'reason': this.contact_reason,
      'app_version': environment.version,
      'platform': pltfm
    }; 
  */
//   public createTicket() {
// 	//https://legacyapi.ponyplace.net/v1/support/
// 	if(!data['subject']){
//         data['subject'] = 'Support Request';
//       }
//       var new_ticket = TicketMaker.create({
//         name: this.user['first_name']+' '+this.user['last_name'],
//         autorespond: true,
//         source: 'API',
//         email: this.user['primary_email'],
//         subject: data['subject'],
//         message: data['message'],
//         reason: data['reason']['name'],
//         user_pk: this.user['pk'],
//         version: data['app_version'],
//         platform: data['platform'],
//         id: null
//       });
//       console.log('new_ticket');
//       console.log(new_ticket);

//       console.log('data.app_version');
//       console.log(data['app_version']);

//       var dataObject = {
//         'action': 'create_ticket',
//         'auth_code': this.authService.token,
//         'user_email': this.user['primary_email'],
//         'user_pk': this.user['pk'],
//         'payload': new_ticket
//       };
//   }

}
