import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginPage } from './pages/login/login.page';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'chat/:thread_pk',
    loadChildren: () => import('./pages/chat/chat.module').then( m => m.ChatPageModule)
  },
  {
    path: ':category/:pk',
    loadChildren: () => import('./pages/listing/listing.module').then( m => m.ListingPageModule)
  },
  {
    path: 'new-chat',
    loadChildren: () => import('./pages/new-chat/new-chat.module').then( m => m.NewChatPageModule)
  },
  {
    path: 'edit-listing',
    loadChildren: () => import('./pages/edit-listing/edit-listing.module').then( m => m.EditListingPageModule)
  },
  {
    path: 'privacy',
    loadChildren: () => import('./pages/tabs/tab5/privacy/privacy.module').then( m => m.PrivacyPageModule)
  },
  {
    path: 'blocked-users',
    loadChildren: () => import('./pages/tabs/tab2/blocked-users/blocked-users.module').then( m => m.BlockedUsersPageModule)
  },
  {
    path: 'archived-chats',
    loadChildren: () => import('./pages/tabs/tab2/archived-chats/archived-chats.module').then( m => m.ArchivedChatsPageModule)
  },
  {
    path: 'new-message',
    loadChildren: () => import('./pages/new-message/new-message.module').then( m => m.NewMessagePageModule)
  },
  {
    path: 'tutorial',
    loadChildren: () => import('./pages/tutorial/tutorial.module').then( m => m.TutorialPageModule)
  },
  {
    path: 'map',
    loadChildren: () => import('./pages/map/map.module').then( m => m.MapPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
