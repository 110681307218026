import { ActionReducerMap } from '@ngrx/store';
import * as fromAuth from './providers/auth/store/auth.reducer';
import * as fromUser from './providers/user/store/user.reducer';
// import * as fromFav from './providers/listing/favStore/fav.reducer';
// import * as fromSale from './providers/listing/saleStore/sale.reducer';
// import * as fromSold from './providers/listing/soldStore/sold.reducer';
// import * as fromInbox from './providers/inbox/store/inbox.reducer';
// import * as fromChat from './pages/chat/store/chat.reducer';
// import * as fromIap from './providers/iap/store/iap.reducer';

export interface AppState {
  auth: fromAuth.State;
  user: fromUser.State;
  // favs: fromFav.State,
  // sale: fromSale.State,
  // sold: fromSold.State,
  // inbox: fromInbox.State,
  // chat: fromChat.State,
  // purchases: fromIap.State
}

export const appReducer: ActionReducerMap<AppState> = {
  auth: fromAuth.authReducer,
  user: fromUser.userReducer,
  // favs: fromFav.favReducer,
  // sale: fromSale.saleReducer,
  // sold: fromSold.soldReducer,
  // inbox: fromInbox.inboxReducer,
  // chat: fromChat.chatReducer,
  // purchases: fromIap.iapReducer
};
