<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-title>tutorial</ion-title>
    <ion-buttons slot="end">
      <ion-button fill="clear" (click)="skipTutorial()">Skip</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-slides pager="true">
    <ion-slide *ngFor="let slide of slideData; let i = index">
      <h2>{{ slide.title }}</h2>
      <p>{{ slide.description }}</p>
      <ion-button fill="solid" (click)="goToSlide(i + 1)">{{ slide.buttonText }}</ion-button>
    </ion-slide>
  </ion-slides>
</ion-content>
