import { Action } from '@ngrx/store';
import { Token, UserLogin } from '../../../providers/auth/auth.types';

export const LOGIN_START = '[Auth] Login Start';
export const VALID_TOKEN = '[Auth] Valid Token';
export const LOGIN_SUCCESS = '[Auth] Login Success';
export const LOGOUT = '[Auth] Logout';
export const LOGIN_FAIL = '[Auth] Login Fail';
export const TOKEN_REFRESH = '[Auth] Token Refresh';

export class ValidToken implements Action {
  readonly type = VALID_TOKEN;
  constructor(
    public payload: Token
  ){}
}
export class LoginStart implements Action {
  readonly type = LOGIN_START;
  constructor(
    public payload: UserLogin
  ){}
}
export class Logout implements Action {
  readonly type = LOGOUT;
}
export class LoginFail implements Action {
  readonly type = LOGIN_FAIL;
  constructor(public payload: string){}
}
export class LoginSuccess implements Action {
  readonly type = LOGIN_SUCCESS;
  constructor(public payload: Token){}
}
export class TokenRefresh implements Action {
  readonly type = TOKEN_REFRESH;
  constructor(public payload?: string){}
}


export type AuthActions =
ValidToken
| LoginStart
| Logout
| LoginFail
| LoginSuccess
| TokenRefresh
;
