import { Action } from '@ngrx/store';
import { User } from '../user.service';
// import { RestError } from '../../rest/rest.types';

export const GET_USER = '[User] Get User';
export const UPDATE_USER = '[User] Update User';
export const CREATE_USER = '[User] Create User';
export const SET_USER = '[User] Set User';
export const SET_USER_FAILED = '[User] Set User Failed';
export const LOGOUT = '[User] Logout';
export const CREATE_FAILED = '[User] Create Failed';

export class GetUser implements Action {
  readonly type = GET_USER;
}
export class SetUser implements Action {
  readonly type = SET_USER;
  constructor(public payload: User){console.log('SetUser action triggered');}
}
export class SetUserFailed implements Action {
  readonly type = SET_USER_FAILED;
  constructor(public payload?: any){}
}
export class CreateUser implements Action {
  readonly type = CREATE_USER;
  constructor(public payload: any){}
}
export class CreateFailed implements Action {
  readonly type = CREATE_FAILED;
  constructor(public payload:any){}
}
export class UpdateUser implements Action {
  readonly type = UPDATE_USER;
  constructor(public payload: any){}
}
export class Logout implements Action {
  readonly type = LOGOUT;
}
export type UserActions =
GetUser
| UpdateUser
| CreateUser
| SetUser
| Logout
| CreateFailed
;
