/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { RestService } from '../rest/rest.service';
import { PageResponse, RestError } from '../rest/rest.types';
import { Store } from '@ngrx/store';
import * as fromApp from '../../app.reducer';
import { Subscription, BehaviorSubject, Observable } from 'rxjs';
import { Token } from '../auth/auth.types';
import { UtilService } from '../util/util.service';
import { map } from 'rxjs/operators';
import { pipe } from 'rxjs';
import { SocketService } from '../socket/socket.service';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  public items: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  // private _tokenSubscription:Subscription;
  // private _token: Token;
  public typingIndicatorDelay = 1500;
  public messages: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  private threadsServerResponse: PageResponse;
  private _messages: any[];
  private serverResponse: PageResponse;
  private _items = [];

  private _totalItems = []; // runs on user login, and accumulates on getThreads()

  constructor(
    private restService: RestService,
    private store: Store<fromApp.AppState>,
    private utilService: UtilService,
  ) {

	// this._tokenSubscription = this.store.select('auth')
	// .subscribe((e:Token)=>{
	//   console.log('chat constructor e:');
	//   console.log(e);
	//   if(e['access']){
	//     console.log('set chat service token');
	//     console.log(e);
	//     this._token = e;

	//     this.getUserThreads()

	//   }else{
	//     console.log('cleared chat service token');
	//     this._token = null;
	//     this.items.next([]);
	//   }
	// })

	console.log('chat service constructor');

}

//   getUserThreads(reset?: boolean): Promise<any> {
//     console.log('getUserThreads()');
//     return new Promise( (resolve,reject)=> {
//       let url = environment.base_api_url + '/chat/';
//       if( !reset && this.serverResponse && this.serverResponse.next ) {
//         url = this.serverResponse.next;
//       }
//       if( reset === true ) {
//         this._items = [];
//       }
//       this.restService.get( url )
//       .subscribe((data)=> {
//         console.log('finished loading user threads:');
//         if(! (data instanceof RestError) ) {
//           console.log(data);
//           this.serverResponse = data;

//           if( data.results ) {
//             if( this._items && this._items.length > 0 ) {
//               this._items = [ ...this._items, ...data.results ];
//             } else {
//               this._items = data.results;
//             }
//           }
//           this.items.next(this._items);
//           resolve(data);
//         }
//         reject();
//       });
//     });
//   }

	findUserThreadPk(userPk: string): string | null {
		const foundThread = this.totalItems.find(thread => 
			thread.other_participants.some(participant => participant.pk === userPk)
		);
		return foundThread ? foundThread.pk : null;
	}

	hasThisThread(ownerPk: string): any|boolean {

		// use ownerPk to find threadPk
		console.log('ownerPk', ownerPk);
		// const thread = this._totalItems.filter((e)=>e.pk===threadPk);
		// if(thread && thread.length) {
		// 	return thread;
		// }
		return false;
	}

	get totalItems() {
		return this._totalItems;
	}

	getAllThreads(): Observable<any> {
		return this.restService.get(`${environment.base_api_url}/chat/?page_size=0`).pipe(
			map(response => {
				if (response && response.results.length) {
					this._totalItems = [...response.results];
				}
				return response.results;
			})
		);
	}

	clearThreads() {
		this._totalItems = [];
		this.resetMessages();
	}

	getThreads(next?: string): Observable<any> {
		return this.restService.get(next || `${environment.base_api_url}/chat/`).pipe(
			map(response => {
				if (response && response.results.length) {
					const merged = [...this._totalItems, ...response.results];
					this._totalItems = merged.filter((item, index, self) => index === self.findIndex(t => t.id === item.id));
				}
				return response;
			})
		);
	}
	
	getMessages(threadPk: string,next?: string): Observable<any> {
		return this.restService.get(next ? next : environment.base_api_url+'/chat/'+threadPk+'/messages/');
	}

	getUserThreadMessagesById(threadPk: string, reset = false): Observable<any> {
		console.log('getUserThreadMessagesById()');

		let url = environment.base_api_url + '/chat/'+threadPk+'/messages/';
		if(!reset && this.threadsServerResponse && this.threadsServerResponse.next) {
			url = this.threadsServerResponse.next;
		}
		if( reset === true ) {
			this._messages = [];
		}
		return this.restService.get( url )
		.pipe( map((data)=> {
			console.log('messages:');
			console.log(data);
			if(!(data instanceof RestError)) {
				this.threadsServerResponse = data;
				if(data.results) {
					if( this._messages && this._messages.length > 0 ) {
						this._messages = [ ...this._messages, ...data.results ];
					} else {
						this._messages = data.results;
					}
				}
				this.messages.next(this._messages);
			}
			return data;
		}));
	}

	public resetMessages(): void {
		this._messages = [];
		this.messages.next(this._messages);
	}

	public getUserThreadById(threadPk: string): Observable<any> {
		return this.restService.get( environment.base_api_url + '/chat/'+threadPk );
	}


}
