export interface RestSuccess {
    status: number;
}
export interface PageResponse { 
	next: string; 
	previous: string; 
	results: any[];
}
//
// export interface RestAuthToken {
//     access: string;
//     refresh: string;
// }
//
// export interface RestEndpointSchema {
//     name: string;
//     description: string;
//     renders: string[];
//     parses: string[];
//     actions: {
//         GET?: {
//             [key: string]: SchemaAction;
//         };
//         POST?: {
//             [key: string]: SchemaAction;
//         };
//         PATCH?: {
//             [key: string]: SchemaAction;
//         };
//     };
//     filters?: SchemaFilter[];
// }
//
// export interface SchemaAction {
//     type: 'text' | 'select' | 'decimal' | 'multi';
//     required: boolean;
//     read_only: boolean;
//     label: string;
//     choices?: SchemaChoice[];
// }
//
// export interface SchemaChoice {
//     value: string;
//     display_name: string;
// }
//
// export interface SchemaFilter {
//     key: string;
//     label: string;
//     help_text: string;
//     type: string | string[];
// }
//
// export interface LoginCredentials {
//     email: string;
//     password: string;
//     username?: string;
// }

export class RestError {
    constructor(public error: any = {}, public message?: string) {}
}
