import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHeaders,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse
} from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { Platform } from '@ionic/angular';
import { HTTP } from '@ionic-native/http/ngx';
import { environment } from '../../../environments/environment';
import { Store } from '@ngrx/store';
import * as fromApp from '../../app.reducer';
import { Subscription } from 'rxjs';
import { Token } from '../../providers/auth/auth.types';
import { AuthService } from '../../providers/auth/auth.service';

type HttpMethod = 'get' | 'post' | 'put' | 'patch' | 'head' | 'delete' | 'upload' | 'download';

@Injectable()
export class NativeHttpInterceptor implements HttpInterceptor {

  private _tokenSubscription: Subscription;

  constructor(
    private nativeHttp: HTTP,
    private authService: AuthService,
    private platform: Platform,
    private store: Store<fromApp.AppState>
  ) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.platform.is('capacitor')) { return next.handle(request); }

    console.log('native interceptor is listening!!');
    console.log(request.url);
    return from(this.handleNativeRequest(request));
  }

  private async _nativeHeaders():Promise<any> {
    return new Promise( (resolve)=> {
      if(this.authService.token && this.authService.token['access']){
        resolve({
          'Content-Type': 'application/json',
          'Accept': 'application/json; version='+environment.api_version,
          'Authorization': 'JWT ' + this.authService.token['access']
        });
      }else{
        resolve({
          'Content-Type': 'application/json',
          'Accept': 'application/json; version='+environment.api_version
        });
      }
    })

  }

  private async handleNativeRequest(request: HttpRequest<any>): Promise<HttpResponse<any>> {

    console.log('handleNativeRequest()');

    const headerKeys = request.headers.keys();
    const header = {};
    let headers:any = [];
    headerKeys.forEach((key) => {
      headers[key] = request.headers.get(key);
    });

    try {
      await this.platform.ready();

      console.log('await platform ready');
      const method = <HttpMethod> request.method.toLowerCase();

      console.log('method', method);

      const nativeHeaders = await this._nativeHeaders();

      console.log('request.url');
      console.log(request.url);

      console.log('nativeHeaders:');
      console.log(nativeHeaders);

      const nativeHttpResponse = await this.nativeHttp.sendRequest(request.url,
        {
          method: method,
          data: request.body ? request.body : {},
          headers: nativeHeaders,
          timeout: 5000,
          responseType: 'json',
          serializer: 'json'
        }
      )
      .catch((err)=> {
        console.log('NATIVE INTERCEPTOR:  some native http error occured');
        if(err){
          // alert(err);
          console.error(err);
        }
        return err;
      });

      let body;

      const response = new HttpResponse({
        body: nativeHttpResponse.data,
        status: nativeHttpResponse.status,
        headers: new HttpHeaders(nativeHttpResponse.headers),
        url: nativeHttpResponse.url,
      });

      return Promise.resolve(response);

    } catch (error) {
      if (!error.status) { return Promise.reject(error); }

      const response = new HttpResponse({
        body: JSON.parse(error.error),
        status: error.status,
        headers: error.headers,
        url: error.url,
      });

      return Promise.reject(response);

    }
  }
}
