import { User, UserMaker } from '../user.service';
import * as UserActions from './user.actions';

export interface State {
  user: User;
  loading: boolean;
  authError: boolean;
  createFailed: boolean;
  blockedUsers: any[];
}

const initialState: State = {
  user: null,
  loading: false,
  authError: false,
  createFailed: false,
  blockedUsers: []
};

export function userReducer(state = initialState, action){
  switch(action.type){
    case UserActions.SET_USER:
      console.log('UserActions.SET_USER');
      // console.log(state);
      // var user:User;
      // if(action.payload instanceof User){
      //   console.log('the dirty work is done!');
      //   user = action.payload;
      // }else{
      //   const user:User = new User(
      //     action.payload.pk,
      //     action.payload.first_name,
      //     action.payload.last_name,
      //     action.payload.avatar,
      //     action.payload.bio,
      //     action.payload.primary_email,
      //     action.payload.other_emails,
      //     action.payload.email_verified,
      //     action.payload.phone,
      //     action.payload.phone_verified,
      //     action.payload.facebook_user_id,
      //     action.payload.facebook_connected,
      //     action.payload.google_user_id,
      //     action.payload.google_connected,
      //     action.payload.allow_marketing,
      //     action.payload.date_joined,
      //     action.payload.modified,
      //     action.payload.location,
      //     action.payload.blocked_users,
      //     action.payload.reported_listings,
      //     action.payload.is_staff,
      //     action.payload.settings
      //   );
      // }

      let user:User = UserMaker.create(action.payload);

      console.log('setting NEW USER');
      console.log(user);

      return {
        ...state,
        user: user,
        authError: false,
        loading: false,
        createFailed: false
      }
    case UserActions.CREATE_FAILED:
      return {
        ...state,
        createFailed: true
      }
    case UserActions.UPDATE_USER:
      return {
        ...state,
        user: UserMaker.create({ ...user, ...action.payload })
      }
    case UserActions.LOGOUT:
      console.log('cancelling the user');
      return {
        ...initialState
      }
    case UserActions.GET_USER:
      return { ...state }
    default:
      return state;
  }
}
