import { Component, OnInit, ViewChild } from '@angular/core';
import { IonSlides, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-tutorial',
  templateUrl: './tutorial.page.html',
  styleUrls: ['./tutorial.page.scss'],
})
export class TutorialPage implements OnInit {

  @ViewChild(IonSlides) slides: IonSlides;

  public slideData = [
    {
      title: "Welcome to the Tutorial",
      description: "Learn how to use our app with a quick guide.",
      buttonText: "Continue"
    },
    {
      title: "Feature Exploration",
      description: "Discover the key features of our application.",
      buttonText: "Continue"
    },
    {
      title: "Get Started",
      description: "You're all set to begin your journey.",
      buttonText: "Let's go!"
    }
  ];
  
  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit() {
  }

  goToSlide(index: number) {
    this.slides.slideTo(index);
  }

  skipTutorial() {
    console.log('skipTutorial()');
    this.modalController.dismiss();
  }

}
