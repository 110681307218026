/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { RestService } from '../rest/rest.service';
import { Store } from '@ngrx/store';
import * as fromApp from '../../app.reducer';
import { Subscription, Subject, Observable } from 'rxjs';
import { Token, TokenMaker } from '../auth/auth.types';
declare let require: any;
import { UtilService } from '../util/util.service';
import { MessageMaker } from '../socket/socket.types';
import { RestError } from '../rest/rest.types';
import { StorageService } from '../storage/storage.service';
import * as AuthActions from '../auth/store/auth.actions';

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  public socketAuthSubject: Subject<any> = new Subject<any>();
  public socketSubject: Subject<any> = new Subject<any>();
  public socketTypingSubject: Subject<any> = new Subject<any>();
  public socketReadSubject: Subject<any> = new Subject<any>();
  private socket: any;
  private awaitingEvent: any = null;
  private awaitingResolve: any;
  private filter: any;

  private _token: Token;
  private _tokenStorageSubscription: Subscription;
  private storeSubscription: Subscription;
  private refreshing = false;

  private createThreadResolve: any;
  private awaitingThreadCreateReceiverPk: string;

  private createMessageResolve: any;
  private awaitingThreadMessageSend: string;
  private awaitingMessageSend: string;

  constructor(
    private restService: RestService,
    private store: Store<fromApp.AppState>,
    private util: UtilService,
    private storageService: StorageService
  ) {
    this.startAuthSubscription();
    console.log('socket service constructor');
  }

  public get token(): Token {
    return this._token;
  }

  public init() {

    console.log('~~~~~~ REQUESTED CHAT SOCKET CONNECTION ~~~~~~~~');

    if(!this.filter){
      const FILTER = require('bad-words');
      this.filter = new FILTER();
      this.prepareBadWords();
    }

    if(this.socket){

      this.socket.reconnect(1, 'onLogin');

    } else {

      this.socket = new ReconnectingWebSocket(
        this.urlProvider,
        [],
        {
          connectionTimeout: 5000,
          reconnectionDelayGrowFactor: 1.2,
          minReconnectionDelay: 1
        }
      );
      this.socket.onerror = (e) => {
        console.log('Chat socket ERROR!');
        console.log(e);
      };

      this.socket.onclose = (e) => {
        console.error('Chat socket closed!');
		/* 
			TODO: flip a flag telling us to re-query the chat messages (if we do connect again)
				to catch any messages that came thru while socket was refreshing.

				if internet is fast, usually only miss typing indicators,
				but if slow, could miss messages.
		*/
        console.log(e);
      };

      this.socket.onopen = (e) => {
        console.log('Chat socket opened! Hooray!');
        if(this.awaitingEvent){
          this.awaitingEvent()
          .then(()=> {
            console.log('finished the awaiting event!');
            this.awaitingEvent = null;
          });
        }
      };

      this.socket.onmessage = (e) => {
        // console.log('onmessage!');
        const response = JSON.parse(e.data);
        // console.log(response);
        this.socketPayloadType(response);
      };

      console.log('finished init for chat socket');

    }

  }

  public startAuthSubscription() {

    console.log('startAuthSubscription()');

    this.storeSubscription = this.store.select('auth')
    .subscribe((authState)=> {
      if(this._token && authState.access && this._token.access && authState.access !== this._token.access) {

        // eslint-disable-next-line max-len
        console.log('%csocket has a NeW tOkEn!', 'font-size:1.6rem padding:0.5rem; background: white;color:red; font-family:"Tahoma", sans-serif;');
		this._token = TokenMaker.create(authState);

		// manually tell socket to reconnect? - yes
		// should we re-query for messages we missed? - yes

		if(this.refreshing) {
			// we called for the refresh! now handle it
			this.refreshing = false;
			if(this.awaitingEvent) {
				this.awaitingEvent()
				.then(()=> {
					console.log('finished the awaiting event!');
					this.awaitingEvent = null;
				});
			}
		}

      }
      if(this._token == null && authState.access && authState.refresh) {
        this._token = TokenMaker.create(authState);
        console.log('set token on socket service');
        console.log(this._token);
      } else if(this._token != null && !authState.access && !authState.refresh) {
        this._token = null;
      }

    //   if(this.refreshing && this._token != null && authState && authState.access && authState.refresh){
    //     console.log('socket service recieved refreshed token!');
    //     this.refreshing = false;
    //     console.log(this._token);

    //     if(this.awaitingEvent){
    //       this.awaitingEvent()
    //       .then(()=> {
    //         console.log('finished the awaiting event!');
    //         this.awaitingEvent = null;
    //       });
    //     }

    //   }
    });
  }

  send(data: any): Promise<any> {

    console.log('send()');
    console.log(data);
	/*
	TODO: if token needs renewing, do it here
	1.) renew token (observable)
	2.) update connection to chat socket using new token (promise)
	3.) send the message(s)
	*/

    return new Promise<void>((resolve, reject)=> {
      if(!this._token) {
        console.log('no token is set! cannot send via socket.');
        reject();
      }

	  if(this.refreshing) {
		console.log('is already refreshing, cannot start again!!');
		reject();
	  }

      // ensure token has not already expired
      if(this._token && this.util.tokenExpired(this._token.access) && this._token.refresh){
        console.log('need to renew the token before continuing with socket');

		// renew token

        this.refreshing = true;
        this.awaitingEvent = () => this.send(data);
        this.socketAuthSubject.next();
      } else {
        console.log('socket will emit');
        this.socket.send(JSON.stringify(data));
        resolve();
      }
    });

  }

  sendMessage(message: string, threadPk: string, listing: any): Promise<any> {
    return new Promise((resolve)=> {

      this.createMessageResolve = resolve;
      this.awaitingThreadMessageSend = threadPk;
      this.awaitingMessageSend = message;

      this.send({
        type: 'chat.send_message',
        thread: threadPk,
        content: message,
        data: {
          pk: listing.pk,
          type: listing.type
        }
      });

    });

  }

  sendTyping(threadPk: string): Promise<any> {
    return this.send({
		type: 'chat.send_typing',
		thread: threadPk,
	});
  }

  sendRead(threadPk: string, messagePk: string): Promise<any> {
    return this.send({
        type: 'chat.send_message_read',
        thread: threadPk,
        message: messagePk
	});
  }

  /* I don't believe creating threads over the socket is a thing? */
//   createThread(receiver: any, message: string, listing: any): Promise<any> {

//     return new Promise((resolve)=> {

//       this.createThreadResolve = resolve;
//       this.awaitingThreadCreateReceiverPk = receiver.pk;
//       this.send({
//         type: 'chat.create_thread',
//         ['other_participants']: [
//           receiver.pk
//         ]
//         // data: {}
//       });
//     });

//   }

  /*
  {
  "pk": "ee37d721-9793-43ca-80d4-56d00f63b179",
  "other_participants": [
    {
      "pk": "cba20fe2-08ff-47ec-b351-788ae62310fc",
      "first_name": "Polly",
      "last_name": "Pocket",
      "avatar": "",
      "is_staff": false
    }
  ],
  "latest_message": null,
  "latest_read": [],
  "unread_count": 0,
  "data": {},
  "archived": false
}
  */

//   public createNewThread(msg: string): Observable<any> {
// 	thread_pk: thread['pk'],
// 	receiver: this.listing.owner_nested,
// 	user: this._user,
// 	thread: thread
// 	return this.restService.post( environment.base_api_url + '/chat/' );
//   }

	public createThread(receiver: any, message: string, listing: any): Observable<any> {
		return this.restService.post(environment.base_api_url+'/chat/',{
			['other_participants']: [
				receiver.pk
			],
			// data: ref
		});
		// .subscribe( (data)=> {
		// 	const newThreadObject = MessageMaker.createThread({
		// 		pk: data.pk,
		// 		['other_participants']: [receiver],
		// 		['latest_read']: null,
		// 		['latest_message']: null,
		// 		['unread_count']: 0
		// 	});
		// 	// this.newThreadCreated = newThreadObject;
		// 	// this.state.results.unshift(newThreadObject)
		// 	this.sendMessage(message, data.pk, listing);
		// });

	}

  stopSocket(reason: string = 'stopSocket') {
    if(this.socket){
      this.socket.close(4011, reason);
    }
  }

  public cleanText(text){
    if(this.filter){
      return this.filter.clean(text);
    }
    return false;
  }

  private socketPayloadType(response: any) {
    const type = response.type;
    if(!type){
      return false;
    }

    switch(type){
      case 'auth.invalid_user':
        this.handleInvalidAuth(response); // both cases should invoke renewal attempt
        break;
      case 'chat.invalid_token':
        this.handleInvalidAuth(response); // both cases should invoke renewal attempt
        break;
      case 'chat.thread':
        this.handleSocketThread(response);
        break;
      case 'chat.message':
        this.handleSocketMessage(response);
        break;
      case 'chat.message_read':
        this.handleSocketMessageRead(response);
        break;
      case 'chat.typing':
        this.handleSocketTyping(response);
        break;
      default:
        break;
    }
  }

	private urlProvider = async () => {
		// const token = await getSessionToken();
		const token = this.token;
		return `${environment.websocket_url2}?token=${token?.access}`;
	};

  private handleSocketThread(response: any) {

    if(this.isThreadWeLastCreated(response)){
      if(this.createThreadResolve){
        console.log('resolving thread create');
        this.createThreadResolve(response); // return back
        this.createThreadResolve = null;
      }
    }

  }

  /* 
  		What are the chat thread socket events we should update
		the thread view with?
		
		user_typing (any thread)
		latest_message (any thread) (place thread at the top)
		message_read (any thread) (could be us reading or them - update last_read)
  		new thread is created (stack to top) (reset pagination / refresh?)

  */

  private handleSocketMessage(response: any) {

    if(this.isMessageWeLastSent(response)){
      if(this.createMessageResolve){
        this.createMessageResolve(response);
        this.createMessageResolve = null;
      }
    }

	// next the subject, and list for new messages
	this.socketSubject.next(response);
  }

  private handleSocketMessageRead(response: any) {
	this.socketReadSubject.next(response);
  }

  private handleSocketTyping(response: any) {
	this.socketTypingSubject.next(response);
  }

  private handleInvalidAuth(response: any) {
    console.log('handleInvalidAuth()', 'font-size:1.2rem; padding:0.5rem;');
    console.log(response);

	/* prevent socket from requesting multiple refreshes and burning a token */

    if(!this.token || !this.token.refresh) {
      console.log('no token for socket to refresh with');
      console.log(response);
      this.stopSocket('No auth token');
      return false;
    }
    this.socketAuthSubject.next(); // tell auth service to attempt renewal
  }

  private isMessageWeLastSent(response) {
    if(response && response.thread === this.awaitingThreadMessageSend && response.content === this.awaitingMessageSend ){
      this.awaitingThreadMessageSend = null;
      this.awaitingMessageSend = null;
      return true;
    }
    return false;
  }

  private isThreadWeLastCreated(response): boolean {
    const receiverPk = response.other_participants[0];
    if(receiverPk && receiverPk === this.awaitingThreadCreateReceiverPk){
      this.awaitingThreadCreateReceiverPk = null;
      return true;
    }
    return false;
  }

  private prepareBadWords(){
      this.filter.removeWords('semen','poop');
  }

}
