import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../../../app.reducer';
import * as fromUser from './user.reducer';

export const selectUserState = createFeatureSelector<AppState, fromUser.State>('user');

export const getUser = createSelector(
  selectUserState,
  (state: fromUser.State) => state.user
);
