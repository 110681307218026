import { Component, OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { Router } from '@angular/router';
import { StorageService } from './providers/storage/storage.service';
import { LoaderService } from './providers/loader/loader.service';
// import OneSignal from 'onesignal-cordova-plugin';
import { User, UserService } from './providers/user/user.service';
// import { OneSignal, OSNotificationOpenedResult } from '@ionic-native/onesignal';
// import { OneSignal, OSNotificationOpenedResult } from '@ionic-native/onesignal';
// import { OneSignal, OSNotificationOpenedResult } from '@ionic-native/onesignal/ngx';
// import OneSignal from 'onesignal-cordova-plugin';
import { OneSignal } from '@ionic-native/onesignal/ngx';

import { Capacitor } from '@capacitor/core';
import { select, Store } from '@ngrx/store';
import * as fromApp from './app.reducer';
import { getUser } from './providers/user/store/user.selectors';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

	private user: User;

	constructor(
		private oneSignal: OneSignal,
		private platform: Platform,
		private storageService: StorageService,
		private router: Router,
		private loaderService: LoaderService,
		private userService: UserService,
		private store: Store<fromApp.AppState>
	) {}

  ngOnInit() {
    this.platform.ready().then(() => this.startApp());
  }

  startApp() {
    console.log('environment.production: ', environment.production );

	this.router.navigate(['/tabs']);

    // const pastSession = this.hasSession();
    // this.router.navigate(['/splash']);
    // setTimeout(() => {
      // this.getData();
    //   if( pastSession ) {
    //     this.router.navigate(['/tabs']);
    //   } else {
    //     this.router.navigate(['/tabs']);
    //   }
    // }, 2000);
  }

  // async getData() {
  //   try {
  //     const url = 'https://devapi.ponyplace.net/api/horse_listings/?page=2';
  //     const params = {};
  //     const headers = {};
  //
  //     // const response = await this.http.get(url, params, headers);
  //     const response = await this.http.sendRequest(url, {
  //       method: 'post',
  //       data: {
  //         'email': 'seth@ponyplace.net',
  //         'password': 'bigPassw0rd'
  //       },
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'Accept': 'application/json; version='+environment.api_version
  //       }
  //     });
  //
  //     console.log('tried HTTP:');
  //     console.log(response.status);
  //     console.log(JSON.parse(response.data)); // JSON data returned by server
  //     console.log(response.headers);
  //
  //   } catch (error) {
  //     console.error(error.status);
  //     console.error(error.error); // Error message as string
  //     console.error(error.headers);
  //   }
  // }

  // getDataAdvancedHttp() {
  //
  //   const options = {
  //     method: 'post',
  //     responseType: 'json'
  //     // data: { id: 12, message: 'test' },
  //     // headers: { Authorization: 'OAuth2: token' }
  //   };
  //   console.log('getDataAdvancedHttp()');
  //
  //   sendRequest('https://devapi.ponyplace.net/api/horse_listings/?page=2', options, function(response) {
  //     // prints 200
  //     console.log('response returned:');
  //     console.log(response.status);
  //     console.log(response);
  //
  //   }, function(response) {
  //     // prints 403
  //     console.log('response returned error:');
  //     console.log(response.status);
  //
  //     //prints Permission denied
  //     console.log(response.error);
  //   });
  //
  // }

  hasSession() {
    return this.storageService.getToken('tokens');
  }

}
