import * as AuthActions from './auth.actions';
import { Token } from '../auth.types';
import { AuthService } from '../../auth/auth.service';

export interface State {
  authError: string;
  loading: boolean;
  access: string;
  refresh: string;
  failed: boolean;
}

const initialState: State = {
  authError: null,
  loading: false,
  access: null,
  refresh: null,
  failed: false
};

export const authReducer = (state = initialState, action: { type: any; payload: { access: string; refresh: string; }; }) => {
	switch(action.type) {
		case AuthActions.VALID_TOKEN:
			const token = new Token(action.payload?.access, action.payload?.refresh);
			console.log('VALID_TOKEN reducer');
			console.log(token);
			console.log('token.access:');
			console.log(token.access);
			console.log(state);
			return {
				...state,
				access: token.access,
				refresh: token.refresh,
				failed:false
			} as State;
		case AuthActions.LOGOUT:
			console.log('USER LOGOUT REDUCER');
			console.log('will return the state set as :');
			console.log({
				...state,
				access: null,
				refresh: null
			});
			return {
				...state,
				access: null,
				refresh: null
			} as State;
		case AuthActions.LOGIN_START:
			return {
				...state,
				authError: null,
				loading: true,
				failed: false
			} as State;
		case AuthActions.LOGIN_FAIL:
			console.log('AuthActions.LOGIN_FAIL');
			return {
				...state,
				authError: action.payload,
				access: null,
				refresh: null,
				loading: false,
				failed: true
			} as unknown as State;
		case AuthActions.LOGIN_SUCCESS:
			return {
				...state
			} as State;
		case AuthActions.TOKEN_REFRESH:
			return state;
		default:
			return state;
	}
};
